import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useAccount } from "wagmi";

interface SignInModalProps {
  open: boolean;
}

const SignInModal: React.FC<SignInModalProps> = ({ open }) => {

  const modalRef = useRef<HTMLDivElement>(null);
  const { isSignedIn, handleSignIn } = useAuth() as any; // Use the hook to get auth status and functions
  const [confirming, setConfirming] = useState(false);
  const { isConnected } = useAccount();

  const [attemptedSignIn, setAttemptedSignIn] = useState(false);

  useEffect(() => {
    if (!isSignedIn && !confirming && !attemptedSignIn && isConnected) {
      setAttemptedSignIn(true); // Mark that we've attempted to sign in
      handleSignIn(setConfirming);
    }
  }, [isSignedIn, confirming, attemptedSignIn,isConnected]); // Dependencies ensure it only runs when needed

  const onSignInClick = () => {
    if (!confirming) {
      handleSignIn(setConfirming);
    }
  };

  return (
    <div data-state="open" className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" style={{ pointerEvents: "auto" }} data-aria-hidden="true" aria-hidden="true">
      <div
        ref={modalRef}
        role="dialog"
        id="radix-:r4f:"
        aria-describedby="radix-:r4h:"
        aria-labelledby="radix-:r4g:"
        data-state="open"
        className="text-sm fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-white p-4 text-center"
        tabIndex={-1}
        style={{ pointerEvents: "auto", border: "1px solid" }}
      >
        <div className="grid gap-4 justify-items-center">
          <div>Sign in to PumpStation</div>
          {confirming && (
          <div className="flex gap-4 py-2 px-4 border border-white rounded-full w-fit">
            <div>Confirm in your wallet</div>
            <div
              data-testid="oval-loading"
              aria-label="oval-loading"
              aria-busy="true"
              role="progressbar"
              className="sc-beySPh bidabG"
            >
              <svg
                width={24}
                height={24}
                viewBox="-20 -20 42 42"
                xmlns="http://www.w3.org/2000/svg"
                stroke="white"
                data-testid="oval-svg"
              >
                <g fill="none" fillRule="evenodd">
                  <g
                    transform="translate(1 1)"
                    strokeWidth={2}
                    data-testid="oval-secondary-group"
                  >
                    <circle
                      strokeOpacity=".5"
                      cx={0}
                      cy={0}
                      r={20}
                      stroke="#4fa94d"
                      strokeWidth={2}
                    />
                    <path d="M20 0c0-9.94-8.06-20-20-20">
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from="0 0 0"
                        to="360 0 0"
                        dur="1s"
                        repeatCount="indefinite"
                      />
                    </path>
                  </g>
                </g>
              </svg>
            </div>
          </div>
          )}
          {!confirming && (
          <button onClick={onSignInClick} className="nes-btn is-primary">Sign message</button>
          )}
          </div>

      </div>
    </div>
  );
}
export default SignInModal;