import { http, createConfig } from 'wagmi'
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  safeWallet,
  trustWallet,
  rabbyWallet,
} from '@rainbow-me/rainbowkit/wallets';

export const soneiumTestnet = {
  id: 1946,
  name: 'Soneium Testnet',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://rpc.minato.soneium.org'] },
  },
  blockExplorers: {
    default: { name: 'Soneiumscan', url: 'https://explorer-testnet.soneium.org/' },
  },
}

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        rabbyWallet,
        metaMaskWallet,
        rainbowWallet,
        // walletConnectWallet,
        coinbaseWallet,
        injectedWallet,
        safeWallet,
        trustWallet,
      ],
    },
  ],
  {
    appName: 'PumpStation',
    projectId: 'YOUR_PROJECT_ID',
  }
);

export const config = createConfig({
  chains: [soneiumTestnet],
  connectors,
  transports: {
    [soneiumTestnet.id]: http(),
  },
})