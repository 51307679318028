// define the FarmPage component
import React, { useEffect, useState } from "react";
import "./tokenpage.css";
import TradeCard from "../TradeCard";

interface TradesTableProps {
  tokenInfo: any;
  allTrades: any;
}

const TradesTable: React.FC<TradesTableProps> = ({ allTrades, tokenInfo }) => {

  return (
    <div className="w-full text-xs sm:text-sm text-gray-400 bg-transparent rounded-lg">
      <div className="flex items-center gap-2 mb-3">
        <label htmlFor="tradesByFollowing">Filter by following</label>
        <button
          type="button"
          role="switch"
          aria-checked="false"
          data-state="unchecked"
          value="on"
          className="peer inline-flex h-4 w-11 shrink-0 cursor-pointer items-center rounded border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-green-300 data-[state=unchecked]:bg-slate-500 dark:focus-visible:ring-slate-300 dark:focus-visible:ring-offset-slate-950 dark:data-[state=checked]:bg-slate-50 dark:data-[state=unchecked]:bg-slate-800"
          id="tradesByFollowing"
        >
          <span
            data-state="unchecked"
            className="pointer-events-none block h-3 w-5 rounded-[.2rem] bg-white shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0 dark:bg-slate-950"
          />
        </button>{" "}
        (0 trades from people you follow)
      </div>
      <div className="overflow-x-auto bg-[#2e303a] rounded-lg">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-[#2e303a] text-sm">
            <tr>
              <th className="p-3 text-left font-normal">account</th>
              <th className="p-3 text-left font-normal ">type</th>
              <th className="p-3 text-left font-normal">ETH</th>
              <th className="p-3 text-left font-normal">{tokenInfo.symbol}</th>
              <th
                className="p-3 text-left font-normal hidden md:table-cell"
                style={{ width: 150 }}
              >
                <div className="flex items-center">
                  date{" "}
                  <button className="ml-1 inline-block align-middle hover:text-gray-300">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 24 24"
                      className="cursor-pointer"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ position: "relative", top: 1 }}
                    >
                      <path d="M12 4C14.7486 4 17.1749 5.38626 18.6156 7.5H16V9.5H22V3.5H20V5.99936C18.1762 3.57166 15.2724 2 12 2C6.47715 2 2 6.47715 2 12H4C4 7.58172 7.58172 4 12 4ZM20 12C20 16.4183 16.4183 20 12 20C9.25144 20 6.82508 18.6137 5.38443 16.5H8V14.5H2V20.5H4V18.0006C5.82381 20.4283 8.72764 22 12 22C17.5228 22 22 17.5228 22 12H20Z" />
                    </svg>
                  </button>
                </div>
              </th>
              <th className="p-3 text-right font-normal hidden sm:table-cell">
                transaction
              </th>
              <th className="p-3 text-right font-normal sm:hidden">txn</th>
            </tr>
          </thead>
          <tbody className="bg-[#2e303a] divide-y divide-gray-700">
            {allTrades && allTrades.map((trade: any) => (
              <TradeCard key={trade.tradeId} trade={trade} />
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full flex justify-center mt-4">
        <div className="justify-self-end mb-20">
          <div className="flex justify-center space-x-2 text-slate-50">
            <button
              disabled
              className="text-lg text-slate-400 cursor-not-allowed"
            >
              [ &lt;&lt; ]
            </button>
            <span className="text-lg">1</span>
            <button
              className="text-lg text-slate-400 cursor-not-allowed"
              disabled
            >
              [ &gt;&gt; ]
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default TradesTable;