import React, { useState } from "react";
import { formatUnits, zeroAddress } from "viem";
import { Select, Space } from "antd";
import { useAccount, useBalance } from "wagmi";

interface FooterProps {
  children?: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ children }) => {
  return (
    <div className="mt-auto py-4 text-gray-400 text-sm md:px-12 px-2">
      <div className="flex justify-between items-center relative">
        <p className="flex-shrink-0">© pumpstation.meme {/* */}2024</p>
        <p className="text-gray-400 absolute left-1/2 transform -translate-x-1/2 text-center text-sm">
          This site is protected by reCAPTCHA and the Google{/* */}{" "}
          <a
            href="https://www.google.com/intl/en/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Privacy Policy
          </a>{" "}
          {/* */}and{/* */}{" "}
          <a
            href="https://www.google.com/intl/en/policies/terms/"
            target="_blank"
            rel="noopener noreferrer"
            className="underline"
          >
            Terms of Service
          </a>{" "}
          {/* */}apply.
        </p>
        {children}
      </div>
    </div>
  );
};

export default Footer;