import React, { useEffect, useRef, useState } from "react";
import { useAccount } from "wagmi";
import { useAuth } from "../../../context/AuthContext";
import { getBaseApiUrl, getImageFormattedUrl } from "../../../utils/constants";
import axios from "axios";
import { notifyError, notifySuccess, notifyWarning } from "../../../hooks/utils";

interface ReplyCardProps {
  reply: any;
  tokenInfo: any;
  setShowPostReply: (value: boolean) => void;
  setReplyTo: (value: string) => void;
}

const ReplyCard: React.FC<ReplyCardProps> = ({ reply, tokenInfo, setShowPostReply, setReplyTo }) => {

  const [isLiked, setIsLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(reply.likes); // Local state for likes count

  const account = useAccount();
  const { isSignedIn } = useAuth() as any;

  const handleLikeReply = async () => {
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!accessToken || !isSignedIn || !account) {
      // Redirect to login or show a message
      notifyWarning("You need to sign in to follow users.");
      return;
    }

    try {
      const response = await axios.post(`${getBaseApiUrl()}/api/like-reply`, {
        userAddress: account.address,
        replyId: reply.replyId,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,
      });

      console.log("Follow response:", response.data);

      if (response.data.success) {
        notifySuccess(response.data.message);
        setIsLiked(!isLiked); // Optionally, update the state to reflect the change
        setLikesCount((prevLikes: any) => (isLiked ? prevLikes - 1 : prevLikes + 1)); // Update likes count
      } else {
        notifyError("Error liking reply")
      }
    } catch (error) {
      console.error("Error liking reply:", error);
      notifyError("An error occurred while trying to like the reply.");
    }
  };

  // useEffect to check if the user has liked the reply
  useEffect(() => {
    const checkIfLiked = async () => {
      const accessToken = localStorage.getItem("access_token"); // Retrieve token from localStorage

      if (!accessToken || !isSignedIn || !account) {
        return;
      }

      try {
        // Check if the reply is liked by the current user
        const liked = reply.likedBy.includes(account.address?.toLowerCase());

        setIsLiked(liked);
      } catch (error) {
        console.error("Error checking like:", error);
      }
    };

    checkIfLiked();
  }, [isSignedIn, account, reply]);

  return (
    <div
      id={`p${reply.replyId}`}
      className=" p-1 text-slate-200 text-sm grid gap-1 nes-container is-dark w-full"
      style={{minHeight: "100px"}}
    >
      <div className="flex flex-wrap gap-2 text-slate-400 text-xs items-start w-full">
        <a href={`/profile/${reply.user.address}`}>
          <span className="flex gap-1  items-center">
            <img
              alt=""
              loading="lazy"
              width={16}
              height={16}
              decoding="async"
              data-nimg={1}
              className="rounded w-4 h-4"
              src="https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=16&img-dpr=2&img-onerror=redirect"
              style={{ color: "transparent", display: "block" }}
            />
            <span
              className="px-1 rounded hover:underline flex gap-1 text-black"
              style={{ backgroundColor: reply.user.color }}
            >
              {reply.user.address.substring(0, 6)}{" "} {reply.user.address === tokenInfo.creator.address ? "(dev)" : ""}
            </span>
          </span>
        </a>
        <div>{reply.createdAt}</div>
        <div className="flex items-center gap-2 w-fit hover:text-red-500 hover:stroke-red-500 cursor-pointer" onClick={handleLikeReply}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={16}
            viewBox="0 0 24 24"
            fill={isLiked ? "red" : "none"}
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-heart "
          >
            <path d="M19 14c1.49-1.46 3-3.21 3-5.5A5.5 5.5 0 0 0 16.5 3c-1.76 0-3 .5-4.5 2-1.5-1.5-2.74-2-4.5-2A5.5 5.5 0 0 0 2 8.5c0 2.3 1.5 4.05 3 5.5l7 7Z" />
          </svg>
          <div>{likesCount}</div>
        </div>
        <div className="cursor-pointer justify-self-end hover:underline" onClick={() => {setShowPostReply(true);setReplyTo(reply.replyId)}}>
          #{reply.replyId} [reply]
        </div>
      </div>
      <div className="flex gap-2 items-start">
        {reply.image !== "" && (
          <img
            alt=""
            loading="lazy"
            width={800}
            height={800}
            decoding="async"
            data-nimg={1}
            className="w-32 object-contain cursor-pointer max-h-[500px]"
            src={getImageFormattedUrl(false, reply.image, 800, "&img-onerror=redirect")}
            style={{ color: "transparent", display: "block" }}
          />
        )}
        <div style={{wordBreak: 'break-all', textAlign: 'left'}}>
          {reply.comment}
        </div>
      </div>
      <div className="flex gap-2" />
    </div>
  );
}
export default ReplyCard;