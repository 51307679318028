import React from "react";
import { TokenTrade } from "../../../types";
import { getImageFormattedUrl, SCAN_URL } from "../../../utils/constants";

interface TradeCardProps {
    trade: TokenTrade;
}

const TradeCard: React.FC<TradeCardProps> = ({
    trade
}) => {

    const tradeTimeAgo = () => {
        const tradeDate = new Date(trade.createdAt);
        const currentDate = new Date();
        const diff = currentDate.getTime() - tradeDate.getTime();
        const diffInMinutes = Math.floor(diff / (1000 * 60));
        if (diffInMinutes < 60) {
            return `${diffInMinutes}m`;
        }
        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) {
            return `${diffInHours}h`;
        }
        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInDays < 30) {
            return `${diffInDays}d`;
        }
        const diffInMonths = Math.floor(diffInDays / 30);
        if (diffInMonths < 12) {
            return `${diffInMonths}mo`;
        }
        const diffInYears = Math.floor(diffInMonths / 12);
        return `${diffInYears}y`;
    };

    return (
        <tr className=" text-sm h-fit-content">
            <td
                className="text-sm px-3 py-3 text-left flex items-center"
                style={{ wordBreak: "break-all" }}
            >
                <a href={`/profile/${trade.user.username}`}>
                    <span className="flex gap-1  items-center">
                        <img
                            alt=""
                            loading="lazy"
                            width={16}
                            height={16}
                            decoding="async"
                            data-nimg={1}
                            className="rounded w-4 h-4"
                            src={getImageFormattedUrl(true, trade.user.profileImage, 16, "&img-onerror=redirect")}
                            style={{ color: "transparent", display: "block" }}
                        />
                        <span
                            className="px-1 rounded hover:underline flex gap-1 text-black"
                            style={{ backgroundColor: trade.user.color }}
                        >
                            {trade.user.username.substring(0, 6)}{" "}
                        </span>
                    </span>
                </a>
            </td>
            <td className={`text-sm px-3 py-3 text-left ${trade.type === "buy" ? "text-green-300" : "text-red-300"} hidden sm:table-cell`}>
                {trade.type === "buy" ? "buy" : "sell"}
            </td>
            <td className="text-sm px-3 py-3 text-left sm:hidden">
                <div className="flex items-center whitespace-nowrap">
                    <span className="text-red-300">sell 14m</span>
                </div>
            </td>
            <td className="text-sm px-3 py-3 text-left overflow-hidden whitespace-nowrap">
                {trade.amountETH.toLocaleString("en-US", { minimumSignificantDigits: 1 })}
            </td>
            <td className="text-sm px-3 py-3 text-left overflow-hidden whitespace-nowrap">
            {trade.amountToken.toLocaleString("en-US", { minimumSignificantDigits: 1 })}
            </td>
            <td className="text-sm px-3 py-3 text-left hidden md:table-cell">{tradeTimeAgo()} ago</td>
            <td className="text-sm px-3 py-3 text-right flex items-center gap-1 justify-end">
                <a
                    href={`${SCAN_URL}/tx/${trade.txHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-blue-500 hover:underline hidden sm:table-cell"
                >
                    {trade.txHash.substring(0, 6)}
                </a>
                <a
                    href={`${SCAN_URL}/tx/${trade.txHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:text-blue-500 hover:underline sm:hidden"
                >
                    <svg
                        width={15}
                        height={15}
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="text-gray-400"
                    >
                        <path
                            d="M3 2C2.44772 2 2 2.44772 2 3V12C2 12.5523 2.44772 13 3 13H12C12.5523 13 13 12.5523 13 12V8.5C13 8.22386 12.7761 8 12.5 8C12.2239 8 12 8.22386 12 8.5V12H3V3L6.5 3C6.77614 3 7 2.77614 7 2.5C7 2.22386 6.77614 2 6.5 2H3ZM12.8536 2.14645C12.9015 2.19439 12.9377 2.24964 12.9621 2.30861C12.9861 2.36669 12.9996 2.4303 13 2.497L13 2.5V2.50049V5.5C13 5.77614 12.7761 6 12.5 6C12.2239 6 12 5.77614 12 5.5V3.70711L6.85355 8.85355C6.65829 9.04882 6.34171 9.04882 6.14645 8.85355C5.95118 8.65829 5.95118 8.34171 6.14645 8.14645L11.2929 3H9.5C9.22386 3 9 2.77614 9 2.5C9 2.22386 9.22386 2 9.5 2H12.4999H12.5C12.5678 2 12.6324 2.01349 12.6914 2.03794C12.7504 2.06234 12.8056 2.09851 12.8536 2.14645Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        />
                    </svg>
                </a>
            </td>
        </tr>
    );
}
export default TradeCard;