import { useEffect, useState } from "react";
import { CreatedTokenInfo } from "../types";
import { getBaseApiUrl } from "../utils/constants";

interface TokenQueryParams {
  page?: number;
  limit?: number;
  sortBy?: string;
  order?: 'asc' | 'desc';
}

const usePaginatedTokensInfo = (queryParams: TokenQueryParams, pollingInterval = 1000) => {
  const [tokensInfo, setTokensInfo] = useState<CreatedTokenInfo[] | null>(null);
  const [pagination, setPagination] = useState<{ currentPage: number, totalPages: number, pageSize: number }>({
    currentPage: 1,
    totalPages: 1,
    pageSize: 10
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    const fetchPaginatedTokens = async () => {
      setLoading(true);
      const { page = 1, limit = 10, sortBy = 'createdAt', order = 'desc' } = queryParams;

      try {
        const response = await fetch(
          `${getBaseApiUrl()}/api/tokens/paginate?page=${page}&limit=${limit}&sortBy=${sortBy}&order=${order}`
        );
        const data = await response.json();

        if (!data.success) {
          console.error("Error fetching paginated tokens:", data.message);
          setLoading(false);
          return null;
        }

        // Map over the returned token data and format each token
        const formattedData = data.data.map((token: any) => ({
          name: token.name,
          symbol: token.symbol,
          marketcap: token.marketcap,
          tokenId: token.tokenId,
          uniV2Pair: token.uniV2Pair,
          liquidityDeployed: token.liquidityDeployed,
          pumpStationPool: token.pumpStationPool,
          address: token.address,
          creator: token.creator,
          description: token.description,
          livestreaming: token.livestreaming,
          badges: token.badges,
          replies: token.replies,
          replyIds: token.replyIds,
          twitter: token.twitter,
          telegram: token.telegram,
          website: token.website,
          image: token.image,
          nsfw: token.nsfw,
          createdAt: token.createdAt,
        }));

        setTokensInfo(formattedData);
        setPagination({
          currentPage: data.pagination.currentPage,
          totalPages: data.pagination.totalPages,
          pageSize: data.pagination.pageSize
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tokens:", error);
        setLoading(false);
      }
    };

    fetchPaginatedTokens();
    // Set up polling interval
    intervalId = setInterval(() => {
      fetchPaginatedTokens();
    }, pollingInterval);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [queryParams, pollingInterval]);

  return { tokensInfo, pagination, loading };
};

export default usePaginatedTokensInfo;
