import { useEffect, useState } from "react";
import { CreatedTokenInfo } from "../types"; // Define this type if you haven't already
import { getBaseApiUrl } from "../utils/constants";

const useAllFollowingUsersCreatedTokens = (userAddress: string, pollingInterval = 1000) => {
    const [followingTokensInfo, setFollowingTokensInfo] = useState<CreatedTokenInfo[] | null>(null);

    useEffect(() => {
        if (!userAddress) return; // Return early if userAddress is not provided

        let intervalId: NodeJS.Timeout;

        const fetchFollowingTokensInfo = async () => {
            try {
                const response = await fetch(
                    `${getBaseApiUrl()}/api/user-following-created-coins?address=${userAddress}`
                );
                const data = await response.json();

                if (!data.success) {
                    console.error("Error fetching following users' tokens:", data.message);
                    return null;
                }

                // Map over the returned token data and format each token
                const formattedData = data.data.map((token: any) => ({
                    name: token.name,
                    symbol: token.symbol,
                    marketcap: token.marketcap,
                    tokenId: token.tokenId,
                    uniV2Pair: token.uniV2Pair,
                    liquidityDeployed: token.liquidityDeployed,
                    pumpStationPool: token.pumpStationPool,
                    address: token.address,
                    creator: token.creator,
                    description: token.description,
                    livestreaming: token.livestreaming,
                    badges: token.badges,
                    replies: token.replies,
                    replyIds: token.replyIds,
                    twitter: token.twitter,
                    telegram: token.telegram,
                    website: token.website,
                    image: token.image,
                    nsfw: token.nsfw,
                    createdAt: token.createdAt,
                }));

                setFollowingTokensInfo(formattedData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchFollowingTokensInfo();
        // Set up polling interval
        intervalId = setInterval(() => {
            fetchFollowingTokensInfo();
        }, pollingInterval);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [userAddress, pollingInterval]);

    return followingTokensInfo;
};

export default useAllFollowingUsersCreatedTokens;
