// define the FarmPage component
import React, { useEffect, useState } from "react";
import "./tokenpage.css";
import { formatNumberWithCommas } from "../../../../utils";
import TokenHolderListItem from "../TokenHolderListItem";
import { CreatedTokenInfo, TokenHolderInfo } from "../../../../types";
import { formatUnits } from "viem";
import { DEX_NAME, getImageFormattedUrl } from "../../../../utils/constants";

interface TokenDescriptionProps {
  tokenInfo: CreatedTokenInfo;
  poolBalance: any;
  reserve0: any
  kingOfPumpProgress: any
  kingOfPump: any
  setGenerateBubbleMapsOpen: any
  bondingCurveProgress: any
  tokenHoldersData: any
}

const TokenDescription: React.FC<TokenDescriptionProps> = ({ bondingCurveProgress, poolBalance, tokenInfo, reserve0, kingOfPumpProgress, kingOfPump, setGenerateBubbleMapsOpen, tokenHoldersData }) => {

  return (
    <div className="w-[350px] bg-transparent text-gray-400 rounded-lg border border-none grid gap-4">
      <div className="flex gap-4">
        <a
          href={tokenInfo.twitter}
          target="_blank"
          rel="noopener noreferrer"
          className=" text-gray-400 hover:underline text-sm nes-text"
        >
          [twitter]
        </a>
        <a
          href={tokenInfo.telegram}
          target="_blank"
          rel="noopener noreferrer"
          className=" text-gray-400 hover:underline text-sm nes-text"
        >
          [telegram]
        </a>
        <a
          href={tokenInfo.website}
          target="_blank"
          rel="noopener noreferrer"
          className=" text-gray-400 hover:underline text-sm nes-text"
        >
          [website]
        </a>
      </div>
      <div className="gap-3 h-fit items-start flex">
        <img
          alt=""
          loading="lazy"
          width={256}
          height={256}
          decoding="async"
          data-nimg={1}
          className="w-32 object-contain cursor-pointer"
          src={getImageFormattedUrl(false, tokenInfo.image, 256, "&img-onerror=redirect")}
          style={{ color: "transparent", display: "block" }}
        />
        <div>
          <div className="font-bold text-lg">{tokenInfo.name} (ticker: {tokenInfo.symbol})</div>
          <div className="text-lg text-gray-400 break-anywhere">
            {tokenInfo.description}
          </div>
        </div>
      </div>
      <div>
        <div className="text-sm text-gray-400 mb-1">
          bonding curve progress: {bondingCurveProgress.toLocaleString("en-US")}%
        </div>
        <progress className="nes-progress is-pattern" value={bondingCurveProgress} max="100"></progress>

      </div>
      <div className="text-sm text-gray-400">
        when the market cap reaches $69,420 all the liquidity from the
        bonding curve will be deposited into {DEX_NAME} and burned. progression
        increases as the price goes up.
        <br />
        <br />
        there are {formatNumberWithCommas(Number(formatUnits(poolBalance || BigInt(0), 18)))} tokens still available for sale in
        the bonding curve and there is {reserve0 ? Number(formatUnits(reserve0 as any, 18)) : "-"} ETH in the bonding curve.
      </div>
      <div className="grid gap-2">
        <div className="text-sm text-gray-400">
          king of the pump progress: {kingOfPumpProgress.toLocaleString("en-US")}%
        </div>
        <progress className="nes-progress is-warning" value={kingOfPumpProgress} max="100"></progress>
        <div className="text-sm text-gray-400">
          dethrone the current king at a ${kingOfPump ? Number(kingOfPump.marketcap).toLocaleString("en-US") : "-"} market cap
        </div>
      </div>
      <div className="grid gap-2">
        <div className="font-bold flex justify-between items-center">
          Holder distribution{" "}
          <button className="whitespace-nowrap text-lg font-medium ring-offset-white transition-colors  py-1 px-2 rounded text-gray-400 hover:bg-gray-600 bg-gray-700"
            onClick={() => setGenerateBubbleMapsOpen(true)}>
            Generate bubble map
          </button>
        </div>
        <div className="text-sm">
          <div className="grid gap-1">
            {tokenHoldersData?.map((holder: TokenHolderInfo, index: number) => (
              <TokenHolderListItem key={index} position={index + 1} tokenHolder={holder} tokenInfo={tokenInfo} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
export default TokenDescription;