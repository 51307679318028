// define the FarmPage component
import React from "react";
import "./tokenpage.css";
import { CreatedTokenInfo } from "../../../../types";
import { formatUnits, } from "viem";
import { getImageFormattedUrl } from "../../../../utils/constants";

interface BuySellComponentProps {
  tokenInfo: CreatedTokenInfo;
  setSlippageSettingsOpen: (open: boolean) => void;
  setToggleBuyTokenAmount: (toggle: boolean) => void;
  toggleBuyTokenAmount: boolean;
  setShowTradeModal: (show: boolean) => void;

  actionTab: number;
  setActionTab: (tab: number) => void;
  setTradeType: (type: string) => void;
  setInputTradeValue: (value: string) => void;
  inputTradeValue: string;
  handleBuyAmount: (amount: number) => void;
  handleSellPercentage: (percentage: number) => void;
  totalReceived: any;
  reserve0: any;
  reserve1: any;
  hasSufficientAllowance: boolean;
  isApproving: boolean;
  isPending: boolean;
  isConfirming: boolean;
  handleApproveTokens: () => void;
  getAmountIn: (reserve0: number, reserve1: number, amount: number) => number;
}

const BuySellComponent: React.FC<BuySellComponentProps> = ({
  setTradeType,
  setInputTradeValue,
  inputTradeValue,
  handleBuyAmount,
  handleSellPercentage,
  totalReceived,
  reserve0,
  reserve1,
  hasSufficientAllowance,
  isApproving,
  isPending,
  isConfirming,
  handleApproveTokens,
  getAmountIn,
  actionTab,
  setActionTab,
  tokenInfo,
  setSlippageSettingsOpen,
  setToggleBuyTokenAmount,
  toggleBuyTokenAmount,
  setShowTradeModal
}) => {

  return (
    <div className="w-full md:w-[350px] grid gap-4">
      <div className="bg-[#2e303a] p-4 grid gap-4 nes-container is-rounded is-dark">
        <div className="grid grid-cols-2 gap-2 mb-4">
          <button className={`text-lg p-2 text-center rounded ${actionTab === 0 ? "nes-btn is-success" : "nes-btn"}`} onClick={() => { setActionTab(0); setTradeType("buy"); }}>
            Buy
          </button>
          <button className={`text-lg p-2 text-center rounded ${actionTab === 1 ? "nes-btn is-error" : "nes-btn"}`} onClick={() => { setActionTab(1); setTradeType("sell") }}>
            Sell
          </button>
        </div>
        {actionTab === 0 && (
          <>
            <div className="flex justify-between w-full gap-2">
              <button className="text-sm py-1 px-2 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300"
                onClick={() => setToggleBuyTokenAmount(!toggleBuyTokenAmount)}
              >
                switch to {toggleBuyTokenAmount ? "ETH" : tokenInfo.symbol}
              </button>
              <button
                className="text-sm py-1 px-2 rounded text-gray-400 hover:bg-gray-800 bg-primary"
                type="button"
                aria-haspopup="dialog"
                aria-expanded="false"
                aria-controls="radix-:rf:"
                data-state="closed"
                onClick={() => setSlippageSettingsOpen(true)}
              >
                Set max slippage
              </button>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center rounded-md relative bg-[#2e303a]">
                <input
                  className="flex h-10 rounded-md border border-slate-200 px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 bg-transparent text-white outline-none w-full pl-3"
                  id="amount"
                  placeholder={"0.0"}
                  type="number"
                  onChange={(e) => setInputTradeValue(e.target.value)}
                  value={inputTradeValue}
                />
                <div className="flex items-center ml-2 absolute right-2 text-sm">
                  <span className="text-white mr-2">{!toggleBuyTokenAmount ? "ETH" : tokenInfo.symbol}</span>
                  <img
                    alt={!toggleBuyTokenAmount ? tokenInfo.symbol : "ETH"}
                    loading="lazy"
                    width={32}
                    height={32}
                    decoding="async"
                    data-nimg={1}
                    className="w-8 h-8 rounded-full"
                    src={!toggleBuyTokenAmount ? "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" : getImageFormattedUrl(false, tokenInfo.image, 0, "")}
                    style={{ color: "transparent" }}
                  />
                </div>
              </div>
              {!toggleBuyTokenAmount && (
                <div className="flex mt-2 bg-[#2e303a] p-1 rounded-lg">
                  <button className="text-sm py-1 -ml-1 px-2 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(0)}>
                    reset
                  </button>
                  <button className="text-sm py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(0.05)}>
                    0.05 ETH
                  </button>
                  <button className="text-sm py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(0.1)}>
                    0.1 ETH
                  </button>
                  <button className="text-sm py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(0.5)}>
                    0.5 ETH
                  </button>
                  <button className="text-sm py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleBuyAmount(1)}>
                    1 ETH
                  </button>
                </div>
              )}
            </div>
            <span className="text-sm text-gray-400">{totalReceived ? !toggleBuyTokenAmount ? Number(formatUnits(totalReceived as any, 18)).toLocaleString("en-US") : getAmountIn(Number(formatUnits(reserve0 as any, 18)), Number(formatUnits(reserve1 as any, 18)), Number(inputTradeValue)).toLocaleString("en-US") : 0} {!toggleBuyTokenAmount ? tokenInfo.symbol : "ETH"}</span>
            <button className="inline-flex items-center justify-center whitespace-nowrap text-lg font-medium nes-btn is-success w-full py-3 rounded-md"
              onClick={() => { setShowTradeModal(true); setTradeType("buy") }}
            >
              place trade
            </button>
          </>
        )}
        {actionTab === 1 && (
          <>
            <div className="flex justify-between w-full gap-2">
              <button
                className="text-sm py-1 px-2 rounded text-gray-400 hover:bg-gray-800 bg-primary"
                type="button"
                aria-haspopup="dialog"
                aria-expanded="false"
                aria-controls="radix-:rf:"
                onClick={() => setSlippageSettingsOpen(true)}
              >
                Set max slippage
              </button>
            </div>
            <div className="flex flex-col">
              <div className="flex items-center rounded-md relative bg-[#2e303a]">
                <input
                  className="flex h-10 rounded-md border border-slate-200 px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 bg-transparent text-white outline-none w-full pl-3"
                  id="amount"
                  placeholder={"0.0"}
                  type="number"
                  onChange={(e) => setInputTradeValue(e.target.value)}
                  value={inputTradeValue}
                />
                <div className="flex items-center ml-2 absolute right-2">
                  <span className="text-white mr-2 text-sm">{tokenInfo.symbol}</span>
                  <img
                    alt={tokenInfo.symbol}
                    loading="lazy"
                    width={32}
                    height={32}
                    decoding="async"
                    data-nimg={1}
                    className="w-8 h-8 rounded-full"
                    src={tokenInfo.image}
                    style={{ color: "transparent" }}
                  />
                </div>
              </div>
              <div className="flex mt-2 bg-[#2e303a] p-1 rounded-lg">
                <button className="text-sm py-1 -ml-1 px-2 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(0)}>
                  reset
                </button>
                <button className="text-sm py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(25)}>
                  25%
                </button>
                <button className="text-sm py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(50)}>
                  50%
                </button>
                <button className="text-sm py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(75)}>
                  75%
                </button>
                <button className="text-sm py-1 px-2 ml-1 rounded bg-primary text-gray-400 hover:bg-gray-800 hover:text-gray-300" onClick={() => handleSellPercentage(100)}>
                  100%
                </button>
              </div>

            </div>
            <span className="text-sm text-gray-400">{totalReceived ? formatUnits(totalReceived as any, 18) : 0} ETH</span>
            {hasSufficientAllowance ? (
              <button
                className="inline-flex items-center justify-center whitespace-nowrap text-lg font-medium nes-btn is-error w-full py-3 rounded-md"
                onClick={() => { setShowTradeModal(true); setTradeType("sell") }}
              >
                place trade
              </button>
            ) : (
              <button
                className="inline-flex items-center justify-center whitespace-nowrap text-lg font-medium nes-btn is-warning w-full py-3 rounded-md"
                disabled={isApproving || isPending || isConfirming}
                onClick={handleApproveTokens}
              >
                {(isApproving || isPending || isConfirming) ? "approving..." : "approve tokens"}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
export default BuySellComponent;