import React from 'react';

interface SorterProps {
    id: string;
    sortOptions: string[];
    selectedSort: string;
    onSortChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const Sorter: React.FC<SorterProps> = ({
    id,
    sortOptions,
    selectedSort,
    onSortChange,
}) => {
    return (
        <div>
            <div className="nes-select is-dark text-sm">
                <select
                    required
                    id={id}
                    value={selectedSort}
                    onChange={onSortChange}
                >
                    {sortOptions.map((option) => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default Sorter;
