// define the FarmPage component
import React, { useEffect, useState } from "react";
import "./tokenpage.css";
import { CreatedTokenInfo, TokenHolderInfo } from "../../../../types";
import { getImageFormattedUrl } from "../../../../utils/constants";

interface ReplyCardDevProps {
  tokenInfo: CreatedTokenInfo;
}

const ReplyCardDev: React.FC<ReplyCardDevProps> = ({ tokenInfo }) => {

  return (
    <div className="gap-1 grid h-fit p-1 text-sm nes-container is-dark">
      <div className="flex gap-1 text-xs">
        <a href={`/profile/${tokenInfo.creator.address}`}>
          <span className="flex gap-1  items-center">
            <img
              alt=""
              loading="lazy"
              width={16}
              height={16}
              decoding="async"
              data-nimg={1}
              className="rounded w-4 h-4"
              src="https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=16&img-dpr=2&img-onerror=redirect"
              style={{ color: "transparent", display: "block" }}
            />
            <span
              className="px-1 rounded hover:underline flex gap-1 text-black"
              style={{ backgroundColor: "rgb(225, 205, 142)" }}
            >
              {tokenInfo.creator.address.substring(0, 6)} (dev)
            </span>
          </span>
        </a>
        <div className="text-slate-400">{new Date(tokenInfo.createdAt).toLocaleString()}</div>
      </div>
      <div className="relative items-start gap-3 text-slate-300 text-xs w-fit flex">
        <img
          alt="name"
          loading="lazy"
          width={800}
          height={800}
          decoding="async"
          data-nimg={1}
          className="w-32 object-contain cursor-pointer max-h-screen"
          src={getImageFormattedUrl(false, tokenInfo.image, 800, "&img-onerror=redirect")}
          style={{ color: "transparent", display: "block" }}
        />
        <div className="grid" style={{ textAlign: "left" }}>
          <div className="font-bold" style={{ marginBottom: "10px" }}>{tokenInfo.name} (ticker: {tokenInfo.symbol})</div>
          <div className="break-anywhere">{tokenInfo.description}</div>
        </div>
      </div>
    </div>
  );
}
export default ReplyCardDev;