// define the FarmPage component
import React, { useEffect, useState } from "react";
import "./tokenpage.css";
import { useParams } from "react-router-dom";
import { formatNumberWithCommas } from "../../../../utils";
import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import useGetTokenHolders from "../../../../hooks/useGetTokenHolders";
import TokenHolderListItem from "../TokenHolderListItem";
import { CreatedTokenInfo, TokenHolderInfo } from "../../../../types";
import useTokenInfo from "../../../../hooks/useTokenInfo";
import PumpStationPoolABI from "../../../../abis/PumpStationPool.abi.json";
import { erc20Abi, formatUnits, parseUnits, zeroAddress } from "viem";
import useGetTradesByToken from "../../../../hooks/useGetTradesByToken";
import { notifyError, notifySuccess } from "../../../../hooks/utils";
import { BigNumber } from "ethers";
import { useAuth } from "../../../../context/AuthContext";
import useKingOfPump from "../../../../hooks/useKingOfPump";
import { DEX_NAME } from "../../../../utils/constants";
import ReplyCard from "../ReplyCard";
import ReplyCardDev from "./ReplyCardDev";

interface ChatComponentProps {
  tokenInfo: CreatedTokenInfo;
  setShowPostReply: (show: boolean) => void;
  setReplyTo: (replyTo: string) => void;
  scrollToBottom: () => void;
}

const ChatComponent: React.FC<ChatComponentProps> = ({ tokenInfo, setShowPostReply, setReplyTo, scrollToBottom }) => {

  return (
    <div className="text-slate-300 grid gap-1 relative">
      <div className="text-lg hover:underline cursor-pointer text-slate-300 w-fit text-sm" onClick={scrollToBottom}>[scroll to bottom]</div>
      <ReplyCardDev tokenInfo={tokenInfo} />
      {tokenInfo.replyIds.map((reply: any) => (
        <ReplyCard key={reply.replyId} reply={reply} setShowPostReply={setShowPostReply} setReplyTo={setReplyTo} tokenInfo={tokenInfo} />
      ))}

      <div className="text-lg justify-self-center hover:underline cursor-pointer" onClick={() => { setShowPostReply(true); setReplyTo("") }}>
        [Post a reply]
      </div>
    </div>
  );
}
export default ChatComponent;