import React, { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../context/AuthContext";
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { DEX_ROUTER, getBaseApiUrl, WETH_ADDRESS } from "../../../utils/constants";
import PumpStationPoolABI from "../../../abis/PumpStationPool.abi.json";
import DexRouterABI from "../../../abis/DexRouter.abi.json";
import { CreatedTokenInfo } from "../../../types";
import { parseUnits } from "viem";
import { notifyError, notifySuccess } from "../../../hooks/utils";

interface TradeModalProps {
  open: boolean;
  onClose: () => void;
  tradeType: string;
  tokenName: string;
  tokenAmount: string;
  ethAmount: string;
  tokenAddress: string;
  tokenInfo: CreatedTokenInfo;
  slippage: number;
}

const TradeModal: React.FC<TradeModalProps> = ({ open, onClose, tradeType, tokenAmount, tokenName, ethAmount, tokenAddress, tokenInfo, slippage }) => {

  const { isSignedIn } = useAuth() as any;
  const account = useAccount();
  const [placingTrade, setPlacingTrade] = useState(false);

  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });

  // Log the transaction arguments and hash for debugging
  useEffect(() => {
    if (hash) {
      console.log("Transaction hash:", hash);
      notifySuccess("Trade placed successfully!");
    }
    if (error) {
      console.error("Error during contract write:", error);
      notifyError("An error occurred while trying to place trade.");
    }
    if (isConfirmed) {
      console.log("Transaction confirmed!");
      notifySuccess("Trade confirmed!");
    }
  }, [hash, error]);

  
  const handleTrade = async () => {
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!accessToken || !isSignedIn || !account) {
      // Redirect to login or show a message
      alert("You need to sign in to trade.");
      return;
    }

    try {
      setPlacingTrade(true);

      const transactionArgs = tradeType === "buy" ? {
        address: tokenInfo.pumpStationPool as any,
        abi: PumpStationPoolABI,
        functionName: 'buyTokens',
        args: [
          parseUnits((Number(tokenAmount ?? "0") * (1 - (slippage / 100))).toFixed(18), 18)
        ],
        value: parseUnits(ethAmount, 18), // 0.0001 ETH
      } : {
        address: tokenInfo.pumpStationPool as any,
        abi: PumpStationPoolABI,
        functionName: 'sellTokens',
        args: [
          parseUnits(tokenAmount ?? "0", 18),
          parseUnits((Number(ethAmount) * (1 - (slippage / 100))).toFixed(18), 18) // fix this
        ]
      };

      writeContract(transactionArgs as any);
    } catch (error) {
      console.error("Error placing trade:", error);
      notifyError("An error occurred while trying to place trade.");
    } finally {
      setPlacingTrade(false);
    }
  };


  const handleTradeDex = async () => {
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!accessToken || !isSignedIn || !account) {
      // Redirect to login or show a message
      alert("You need to sign in to trade.");
      return;
    }

    try {
      setPlacingTrade(true);

      const transactionArgs = tradeType === "buy" ? {
        address: DEX_ROUTER,
        abi: DexRouterABI,
        functionName: 'swapExactETHForTokens',
        args: [
          parseUnits((Number(tokenAmount ?? "0") * (1 - (slippage / 100))).toFixed(18), 18),
          [WETH_ADDRESS, tokenAddress],
          account.address,
          Math.floor(Date.now() / 1000) + 10, // 10 seconds
        ],
        value: parseUnits(ethAmount, 18), // 0.0001 ETH
      } : {
        address: DEX_ROUTER,
        abi: DexRouterABI,
        functionName: 'swapExactTokensForETH',
        args: [
          parseUnits(tokenAmount ?? "0", 18),
          parseUnits((Number(ethAmount) * (1 - (slippage / 100))).toFixed(18), 18), // fix this
          [tokenAddress, WETH_ADDRESS],
          account.address,
          Math.floor(Date.now() / 1000) + 10, // 10 seconds
        ]
      };

      writeContract(transactionArgs as any);
    } catch (error) {
      console.error("Error placing trade:", error);
      notifyError("An error occurred while trying to place trade.");
    } finally {
      setPlacingTrade(false);
    }
  };


  const modalRef = useRef<HTMLDivElement>(null);

  // Handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    // Add event listener to detect clicks
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove event listener when component is unmounted or `open` changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  // Return null if the modal is not open
  if (!open) return null;

  return (
    <div data-state="open" className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" style={{ pointerEvents: "auto" }} data-aria-hidden="true" aria-hidden="true">
      <div
        ref={modalRef}
        role="dialog"
        id="radix-:r4f:"
        aria-describedby="radix-:r4h:"
        aria-labelledby="radix-:r4g:"
        data-state="open"
        className="text-sm fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-white"
        tabIndex={-1}
        style={{ pointerEvents: "auto", border: "1px solid" }}
      >
        <div className="grid gap-2">
          <label className="mb-1 text-sm font-semibold text-blue-400" htmlFor="text">
            add a comment
          </label>
          <textarea
            className="bg-[#2a2a3b] border border-slate-200 rounded-md p-2 h-24"
            id="text"
            placeholder="(optional)"
            defaultValue={""}
          />
        </div>
        <div>{tradeType} {tokenAmount} {tokenName} for {ethAmount} ETH</div>
        <button className={`inline-flex items-center justify-center whitespace-nowrap text-sm font-medium nes-btn ${tradeType === "buy" ? "is-success" : "is-error"} w-full py-3 rounded-md`}
        disabled={placingTrade || isPending || isConfirming}
        onClick={() => tokenInfo.liquidityDeployed ? handleTradeDex() : handleTrade()}>
          {(placingTrade || isPending || isConfirming) ? "placing trade..." : "place trade"}
        </button>
        <div className="text-slate-50 hover:font-bold hover:text-slate-50 cursor-pointer w-fit justify-self-center" onClick={onClose}>
          [cancel]
        </div>

      </div>
    </div>
  );
}
export default TradeModal;