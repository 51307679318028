import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';
import debounce from 'lodash.debounce'; // Add lodash for debounce functionality

const CandlestickChart = ({ trades }) => {
    const [ohlcData, setOhlcData] = useState([]);
    const [timeframe, setTimeframe] = useState(1); // Default to 1-minute timeframe
    const [loading, setLoading] = useState(true);

    const [viewRange, setViewRange] = useState({
        start: dayjs().subtract(240, 'minute').toDate(), // Initial 2 minutes ago
        end: dayjs().add(10, 'minute').toDate(), // Initial 1 minute ahead
    });

    // Memoized function to group trades by timeframe and calculate OHLC
    const groupTradesByTimeframe = (trades, timeframe, start, end) => {
        const grouped = {};
        let lastOHLC = null;
        const sortedTrades = trades.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

        sortedTrades.forEach(trade => {
            const tradeTime = dayjs(trade.createdAt)
                .startOf('minute')
                .subtract(dayjs(trade.createdAt).minute() % timeframe, 'minute')
                .format();
            const price = trade.amountETH / trade.amountToken;

            if (!grouped[tradeTime]) {
                grouped[tradeTime] = {
                    open: lastOHLC ? lastOHLC.close : price, // Use last close for open
                    high: price,
                    low: price,
                    close: price,
                };
            } else {
                grouped[tradeTime].close = price; // Update close price to the latest
                grouped[tradeTime].high = Math.max(grouped[tradeTime].high, price);
                grouped[tradeTime].low = Math.min(grouped[tradeTime].low, price);
            }

            lastOHLC = { ...grouped[tradeTime] };
        });

        const ohlcDataArray = [];
        let currentTime = dayjs(start);

        // Ensure candles are only generated within the startTime and endTime window
        while (currentTime.isBefore(end) || currentTime.isSame(end)) {
            const timeKey = currentTime.format();
            if (!grouped[timeKey] && lastOHLC) {
                ohlcDataArray.push({
                    x: new Date(timeKey),
                    y: [lastOHLC.close, lastOHLC.close, lastOHLC.close, lastOHLC.close],
                });
            } else if (grouped[timeKey]) {
                ohlcDataArray.push({
                    x: new Date(timeKey),
                    y: [grouped[timeKey].open, grouped[timeKey].high, grouped[timeKey].low, grouped[timeKey].close],
                });
                lastOHLC = { ...grouped[timeKey] };
            }
            currentTime = currentTime.add(timeframe, 'minute');
        }

        return ohlcDataArray;
    };
    const getYAxisRange = useMemo(() => {
        if (ohlcData.length === 0) return [0, 0];
        let prices = ohlcData.flatMap(point => point.y);
        // limit prices total to 10000
        // if (prices.length > 3000) {
        //     prices = prices.slice(prices.length - 3000);
        // }

        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        const padding = (maxPrice - minPrice) * 0.1;
        return [minPrice - padding, maxPrice + padding];
    }, [ohlcData]);


    const options = {
        chart: {
            type: 'candlestick',
            height: 350,
            background: '#1f1f1f',
            foreColor: '#ffffff',
            animations: {
                enabled: false, // Disable animations for better performance
            },
            events: {
                zoomed: (chartContext, { xaxis }) => {
                    const newStart = new Date(xaxis.min);
                    const newEnd = new Date(xaxis.max);
                    setViewRange({ start: newStart, end: newEnd });
                },
                scrolled: (chartContext, { xaxis }) => {
                    const newStart = new Date(xaxis.min);
                    const newEnd = new Date(xaxis.max);
                    setViewRange({ start: newStart, end: newEnd });
                },
            },
        },
        grid: {
            borderColor: '#444', // Dark grid lines
            xaxis: {
                lines: {
                    show: true, // Enable vertical grid lines
                },
            },
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: '#ffffff', // White font color for x-axis labels
                },
            },
            axisBorder: {
                color: '#777', // Darker border
            },
            axisTicks: {
                color: '#777', // Darker ticks
            },
            crosshairs: {
                show: true, // Show vertical crosshairs
            },
        },
        yaxis: {
            min: getYAxisRange[0],
            max: getYAxisRange[1],
            labels: {
                style: { colors: '#ffffff' },
                formatter: function (value) {

                const formatWithSubscript = (value) => {
                    const [integerPart, decimalPart] = Number(value).toFixed(18).split('.');
                    if (decimalPart && decimalPart.match(/^0+/)) {
                        const zeroCount = decimalPart.match(/^0+/)[0].length;
                                        //         // Convert the number of zeros into subscript (works for 0-9)
                        const subscriptMap = {
                            0: '₀',
                            1: '₁',
                            2: '₂',
                            3: '₃',
                            4: '₄',
                            5: '₅',
                            6: '₆',
                            7: '₇',
                            8: '₈',
                            9: '₉',
                        };
                        return `${integerPart}.0${subscriptMap[zeroCount]}${decimalPart.replace(/^0+/, '')}`;
                    }
                    return value; // Return as-is if no leading zeros in decimal part
                };

                    // Return the number as usual if there are not more than 2 zeros after decimal
                    return formatWithSubscript(value);
                },
            },
            opposite: true,
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const open = w.globals.seriesCandleO[seriesIndex][dataPointIndex];
                const high = w.globals.seriesCandleH[seriesIndex][dataPointIndex];
                const low = w.globals.seriesCandleL[seriesIndex][dataPointIndex];
                const close = w.globals.seriesCandleC[seriesIndex][dataPointIndex];

                // Helper function to format a number with trailing zeros as subscript
                const formatWithSubscript = (value) => {
                    const [integerPart, decimalPart] = Number(value).toFixed(18).split('.');
                    if (decimalPart && decimalPart.match(/^0+/)) {
                        const zeroCount = decimalPart.match(/^0+/)[0].length;
                        const subscriptZeros = Array.from(zeroCount.toString()).map(
                            (digit) => digit === '0' ? '₀' : zeroCount.toString()
                        ).join('');
                        return `${integerPart}.0<sub>${subscriptZeros}</sub>${decimalPart.replace(/^0+/, '')}`;
                    }
                    return value; // Return as-is if no leading zeros in decimal part
                };

                const formattedOHLC = formatWithSubscript(open);
                const formattedOHLC1 = formatWithSubscript(high);
                const formattedOHLC2 = formatWithSubscript(low);
                const formattedOHLC3 = formatWithSubscript(close);
                // Format the tooltip using subscripts
                return `<div style="padding: 5px;">
                          OPEN: ${formattedOHLC}
                          </br>HIGH: ${formattedOHLC1}
                          </br>LOW: ${formattedOHLC2}
                          </br>CLOSE: ${formattedOHLC3}
                        </div>`;
            },
        },
        plotOptions: {
            candlestick: {
                colors: { upward: '#00B746', downward: '#EF403C' },
                wick: { useFillColor: true },
            },
        },
    };

    const series = loading ? [{ data: [{ x: new Date(), y: [0, 0, 0, 0] }] }] : [{ data: ohlcData }];

    // Debounce timeframe change handler to avoid frequent updates
    const handleTimeframeChange = useCallback(
        debounce((value) => setTimeframe(value), 300),
        []
    );

    // Use useMemo to memoize grouped trades data to avoid reprocessing on every render
    const groupedTrades = useMemo(() => {
        if (trades.length === 0) return [];
        return groupTradesByTimeframe(trades, timeframe, viewRange.start, viewRange.end);
    }, [trades, timeframe]);


    useEffect(() => {
        setLoading(true);
        if (trades.length > 0) {
            setOhlcData(groupedTrades);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [trades, groupedTrades]);

    return (
        <div>
            <div>
                <label style={{ color: '#fcfcfc', marginRight: "10px" }} className='text-sm'>Select Timeframe: </label>
                <select
                className='text-sm'
                    value={timeframe}
                    onChange={(e) => handleTimeframeChange(parseInt(e.target.value, 10))}
                >
                    <option value={1}>1 Minute</option>
                    <option value={5}>5 Minutes</option>
                    <option value={15}>15 Minutes</option>
                </select>
            </div>
            <Chart options={options} series={series} type="candlestick" height={350} />
        </div>
    );
};

export default React.memo(CandlestickChart);
