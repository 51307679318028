// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import axios from 'axios';
import { useAccount, useSignMessage } from 'wagmi';
import { getBaseApiUrl } from '../utils/constants';

// Define the type for the AuthContext state
interface AuthContextType {
  isSignedIn: boolean;
  loading: boolean;
  checkAuth: () => Promise<void>;
  handleSignIn: (setConfirming: React.Dispatch<React.SetStateAction<boolean>>) => Promise<void>;
}

// Define the context with a default value
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define a type for the provider's props
interface AuthProviderProps {
  children: ReactNode;
}

// Provider component
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { address, isConnected } = useAccount();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loading, setLoading] = useState<boolean>(true); // New loading state
  const { signMessageAsync } = useSignMessage();

  // Function to check if the user has a valid JWT
  const checkAuth = async () => {
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!accessToken || !address) {
      setIsSignedIn(false);
      setLoading(false); // Stop loading
      return;
    }

    setLoading(true); // Start loading

    try {
      const response = await axios.get(`${getBaseApiUrl()}/api/validate-token?address=${address}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        withCredentials: true,
      });

      if (response.data.success) {
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    } catch (error) {
      // console.log('No valid token found or error occurred:', error);
      setIsSignedIn(false);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleSignIn = async (setConfirming: React.Dispatch<React.SetStateAction<boolean>>) => {
    if (!isConnected || !address) return;

    try {
      setConfirming(true); // Start loading
      const message = `Sign this message to confirm your identity: ${address}`;
      const signature = await signMessageAsync({ message });

      const response = await axios.post(
        `${getBaseApiUrl()}/api/auth`,
        { address, signature },
        { withCredentials: true }
      );

      if (response.data.success) {
        localStorage.setItem('access_token', response.data.accessToken);
        setIsSignedIn(true);
      } else {
        console.error('Error during authentication:', response.data.message);
      }
    } catch (error) {
      console.error('Error signing message:', error);
    } finally {
      setConfirming(false); // Stop loading
    }
  };

  useEffect(() => {
    if (isConnected) {
      checkAuth();
    }
  }, [isConnected, address]);

  return (
    <AuthContext.Provider value={{ isSignedIn, loading, checkAuth, handleSignIn }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};
