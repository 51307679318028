import React, { useEffect, useRef, useState } from "react";
import { TokenHolderInfo } from "../../../types";
import { SCAN_URL } from "../../../utils/constants";

interface TokenHolderListItemProps {
  position: number;
  tokenHolder: TokenHolderInfo;
  tokenInfo: any;
}

const TokenHolderListItem: React.FC<TokenHolderListItemProps> = ({ position, tokenHolder, tokenInfo }) => {

  const isDev = tokenInfo.creator.address.toLowerCase() === tokenHolder.address;
  const isBondingCurve = tokenInfo.pumpStationPool.toLowerCase() === tokenHolder.address;

  // console.log(tokenInfo, tokenHolder);

  return (
    <div className="flex justify-between">
      <a
        className="hover:underline"
        href={`${SCAN_URL}/address/${tokenHolder.address}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {position}. {tokenHolder.address.substring(0, 6)} {isBondingCurve && ("🏦 (bonding curve)")} {isDev && ("🤵‍♂️ (dev)")}
      </a>
      <div>{tokenHolder.percentage.toFixed(2)}%</div>
    </div>
  );
}
export default TokenHolderListItem;