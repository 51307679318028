// define the FarmPage component
import React, { useEffect, useState } from "react";
import "./tokenpage.css";
import { useParams } from "react-router-dom";
import { useAccount, useReadContract, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import useGetTokenHolders from "../../../../hooks/useGetTokenHolders";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import BubbleMaps from "../BubbleMaps"
import PostReply from "../PostReply";
import useTokenInfo from "../../../../hooks/useTokenInfo";
import SlippageSettings from "../SlippageSettings";
import TradeModal from "../TradeModal";
import Footer from "../../../../components/Footer";
import PumpStationPoolABI from "../../../../abis/PumpStationPool.abi.json";
import DexRouterABI from "../../../../abis/DexRouter.abi.json";
import { erc20Abi, formatUnits, parseUnits, zeroAddress } from "viem";
import useGetTradesByToken from "../../../../hooks/useGetTradesByToken";
import { notifyError, notifySuccess } from "../../../../hooks/utils";
import { BigNumber } from "ethers";
import { useAuth } from "../../../../context/AuthContext";
import useKingOfPump from "../../../../hooks/useKingOfPump";
import { DEX_ROUTER, WETH_ADDRESS } from "../../../../utils/constants";
import LogoPumpStation from "../../../../assets/img/logo-pumpstation.png";
import BuySellComponent from "./BuySellComponent";
import TokenDescription from "./TokenDescription";
import ChartContainer from "./ChartContainer";
import ChatComponent from "./ChatComponent";
import TradesTable from "./TradesTable";
import TopChartInfo from "./TopChartInfo";

const TokenPage = () => {

  const account = useAccount();

  let { tokenAddress } = useParams() as any;

  const { generalInfo: tokenInfo, loading: loadingTokenInfo } = useTokenInfo(tokenAddress);

  const kingOfPump = useKingOfPump();

  const allTrades = useGetTradesByToken(tokenAddress);
  const tokenHoldersData = useGetTokenHolders(tokenAddress);

  const [toggleChatOrTrades, setToggleChatOrTrades] = useState(true);
  const [generateBubbleMapsOpen, setGenerateBubbleMapsOpen] = useState(false);
  const [slippageSettingsOpen, setSlippageSettingsOpen] = useState(false);

  const [toggleBuyTokenAmount, setToggleBuyTokenAmount] = useState(false)
  const [actionTab, setActionTab] = useState(0)
  const [showPostReply, setShowPostReply] = useState(false);
  const [replyTo, setReplyTo] = useState("");

  const [showTradeModal, setShowTradeModal] = useState(false);
  const [tradeType, setTradeType] = useState("buy");

  const [inputTradeValue, setInputTradeValue] = useState("");


  const { data: reserve0 } = useReadContract({
    abi: PumpStationPoolABI,
    address: tokenInfo ? tokenInfo.liquidityDeployed ? tokenInfo.uniV2Pair : tokenInfo.pumpStationPool as any : zeroAddress,
    functionName: 'reserve0',
    args: [],
  });
  const { data: reserve1 } = useReadContract({
    abi: PumpStationPoolABI,
    address: tokenInfo ? tokenInfo.liquidityDeployed ? tokenInfo.uniV2Pair : tokenInfo.pumpStationPool as any : zeroAddress,
    functionName: 'reserve1',
    args: [],
  });
  const { data: totalReceived } = useReadContract({
    abi: tokenInfo ? tokenInfo.liquidityDeployed ? DexRouterABI : PumpStationPoolABI : PumpStationPoolABI,
    address: tokenInfo ? tokenInfo.liquidityDeployed ? tokenInfo.uniV2Pair : tokenInfo.pumpStationPool as any : zeroAddress,
    functionName: tokenInfo ? tokenInfo.liquidityDeployed ? 'getAmountOut' : 'getAmountOutPublic' : 'getAmountOutPublic',
    args: tokenInfo ? tokenInfo.liquidityDeployed ? 
    [parseUnits(inputTradeValue === "" ? "0" : inputTradeValue, 18), tradeType === "buy" ? reserve0 : reserve1, tradeType === "buy" ? reserve1 : reserve0, tradeType === "buy" ? WETH_ADDRESS : tokenAddress, tradeType === "buy" ? tokenAddress : WETH_ADDRESS]
    : [parseUnits(inputTradeValue === "" ? "0" : inputTradeValue, 18), tradeType === "buy" ? reserve0 : reserve1, tradeType === "buy" ? reserve1 : reserve0] : [],
  });

  const { data: userTokenBalance } = useReadContract({
    abi: erc20Abi,
    address: tokenAddress,
    functionName: 'balanceOf',
    args: [account ? account.address as any : zeroAddress],
  });

  const { data: poolBalance } = useReadContract({
    abi: erc20Abi,
    address: tokenAddress,
    functionName: 'balanceOf',
    args: [tokenInfo ? tokenInfo.liquidityDeployed ? tokenInfo.uniV2Pair : tokenInfo.pumpStationPool as any : zeroAddress],
  });

  const { data: tokenMarketCap } = useReadContract({ // missing do when liq depl
    abi: PumpStationPoolABI,
    address: tokenInfo ? tokenInfo.pumpStationPool as any : zeroAddress,
    functionName: 'getCurrentCap',
    args: [],
  });

  const getAmountIn = (reservesIn: number, reservesOut: number, tokenOutQuantity: number): number => {
    // Calculate numerator and denominator
    const numerator = reservesIn * tokenOutQuantity;
    const denominator = reservesOut - tokenOutQuantity;

    // Return the calculated amount in
    return (numerator / denominator);
  };

  const [slippage, setSlippage] = useState(1);

  const [bondingCurveProgress, setBondingCurveProgress] = useState(0);
  const [kingOfPumpProgress, setKingOfPumpProgress] = useState(0);
  useEffect(() => {
    if (tokenMarketCap) {
      setBondingCurveProgress((Number(formatUnits(tokenMarketCap as any, 18)) / 69420) * 100);
    }
  }, [tokenMarketCap]);

  useEffect(() => {
    if (tokenMarketCap && kingOfPump) {
      setKingOfPumpProgress((Number(formatUnits(tokenMarketCap as any, 18)) / Number(formatUnits(kingOfPump.marketcap as any, 0))) * 100);
    }
  }, [tokenMarketCap, kingOfPump]);


  const handleCopy = () => {
    // Get the input element by ID
    const input = document.getElementById('copy-ca') as HTMLInputElement;

    // Check if input exists and has a value
    if (input && input.value) {
      navigator.clipboard.writeText(input.value)
        .then(() => {
          notifySuccess("Copied to clipboard successfully!");
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    }
  };

  // do a function to set the % of sell based on the userTokenBalance 

  const handleSellPercentage = (percentage: number) => {
    setInputTradeValue((Number(formatUnits(userTokenBalance as any, 18)) * percentage / 100).toString());
  }

  const handleBuyAmount = (amount: number) => {
    setInputTradeValue(amount.toString());
  }

  const [hasSufficientAllowance, setHasSufficientAllowance] = useState(false);

  // Read the allowance and refetch on demand
  const { data: allowance, refetch: refetchAllowance } = useReadContract({
    abi: erc20Abi,
    address: tokenAddress,
    functionName: "allowance",
    args: [account ? account.address as any : zeroAddress, tokenInfo ? tokenInfo.pumpStationPool as any : zeroAddress],
  });


  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } = useWaitForTransactionReceipt({
    hash,
  });
  const { isSignedIn } = useAuth() as any;
  const [isApproving, setApproving] = useState(false);

  const handleApproveTokens = async () => {
    const accessToken = localStorage.getItem('access_token'); // Retrieve token from localStorage

    if (!accessToken || !isSignedIn || !account) {
      // Redirect to login or show a message
      alert("You need to sign in to trade.");
      return;
    }

    try {
      setApproving(true);

      const transactionArgs = {
        abi: erc20Abi,
        address: tokenAddress,
        functionName: 'approve',
        args: [tokenInfo ? tokenInfo.liquidityDeployed ? DEX_ROUTER : tokenInfo.pumpStationPool : zeroAddress, parseUnits(inputTradeValue, 18)],
      };

      writeContract(transactionArgs as any);
    } catch (error) {
      console.error("Error approving tokens:", error);
      notifyError("An error occurred while trying to approve tokens.");
    } finally {
      setApproving(false);
    }
  };

  useEffect(() => {
    if (allowance && inputTradeValue) {
      const isAllowed = BigNumber.from(allowance).gte(parseUnits(inputTradeValue, 18));
      setHasSufficientAllowance(isAllowed);
    } else {
      setHasSufficientAllowance(false); // Reset when no allowance or trade value
    }
  }, [allowance, inputTradeValue]); // Update hasSufficientAllowance when allowance or trade value changes

  useEffect(() => {
    // Refetch allowance automatically when input trade value changes
    refetchAllowance();
  }, [inputTradeValue]); // Optionally refetch allowance when input value changes

  useEffect(() => {
    // Refetch allowance when the transaction is confirmed
    if (isConfirmed) {
      refetchAllowance();
    }
  }, [isConfirmed, refetchAllowance]);


  const [mobilePage, setMobilePage] = useState(0);

  if (!tokenInfo && !loadingTokenInfo) {
    return (
      <PageContainer>
        <main className="h-full" style={{ minHeight: '100vh' }}>
          <div className="text-white flex items-center justify-center text-center h-fit mx-auto p-4">
            <div className="">
              <svg
                className="w-80 mx-auto"
                viewBox="0 0 539 451"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 295.5L492 116C492 116 547.5 135 537.5 228C527.5 321 390 372 390 372L300 345L332.5 408.5C267.5 438.5 213.5 452.5 182.5 450.5C151.5 448.5 115.5 449 74 403.5C32.5 358 36 295.5 36 295.5Z"
                  fill="black"
                  fillOpacity="0.1"
                />
                <path
                  d="M470.947 161.304C432.771 200.378 401.81 229.18 380.427 248.187C369.735 257.69 361.438 264.745 355.831 269.411C355.242 269.901 354.683 270.364 354.154 270.801L233.113 107.167C235.279 105.188 238.461 102.418 243.094 98.6121C257.347 86.902 284.906 65.7353 337.904 28.1688C386.822 -6.50546 437.236 8.80343 467.214 42.2822C497.544 76.1541 505.202 126.243 470.947 161.304Z"
                  fill="white"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M351.065 149.236C367.757 172.887 375.745 198.897 375.516 221.589C375.288 244.295 366.925 262.971 351.737 273.69C336.548 284.409 316.15 286.032 294.679 278.641C273.223 271.254 251.392 255.013 234.7 231.363C218.008 207.712 210.02 181.702 210.248 159.01C210.477 136.304 218.84 117.628 234.028 106.909C249.217 96.1897 269.615 94.5665 291.085 101.958C312.542 109.345 334.373 125.586 351.065 149.236Z"
                  fill="#E1E1E1"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M16.0141 246.223C7.54956 186.447 49.3591 131.097 109.398 122.596L219.075 107.065C219.075 107.065 242.161 156.219 249.79 221.618C257.419 287.017 249.728 323.532 249.728 323.532L140.051 339.062C80.0118 347.564 24.4786 305.999 16.0141 246.223Z"
                  fill="#5FCB88"
                />
                <path
                  d="M110.727 130.019L214.27 115.357C214.602 116.154 214.962 117.031 215.347 117.984C217.577 123.498 220.647 131.564 223.958 141.664C230.587 161.887 238.146 190.155 241.908 222.374C245.694 254.792 245.683 279.958 244.747 296.919C244.279 305.4 243.58 311.832 243.008 316.095C242.991 316.218 242.975 316.34 242.958 316.46L139.208 331.152C83.6668 339.016 32.266 300.367 24.4011 244.826C16.5362 189.285 55.1855 137.884 110.727 130.019Z"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M69.0978 252.344C71.1796 251.763 73.3384 252.979 73.9197 255.061L75.8974 262.144C76.4787 264.226 75.2623 266.385 73.1805 266.966C71.0987 267.548 68.9399 266.331 68.3586 264.249L66.3809 257.166C65.7996 255.084 67.016 252.925 69.0978 252.344ZM79.9079 277.183C81.461 275.68 83.9387 275.721 85.4418 277.274C92.6431 284.715 101.241 289.943 110.217 292.733C112.281 293.374 113.434 295.568 112.792 297.632C112.15 299.696 109.957 300.849 107.893 300.207C97.6304 297.017 87.9063 291.075 79.8173 282.717C78.3141 281.164 78.3547 278.686 79.9079 277.183ZM124.884 300.453C124.554 298.317 126.018 296.318 128.154 295.988L137.353 294.569C139.489 294.239 141.488 295.703 141.818 297.839C142.148 299.976 140.683 301.975 138.547 302.304L129.348 303.724C127.212 304.054 125.213 302.589 124.884 300.453Z"
                  fill="white"
                />
              </svg>
              <h2 className=" text-4xl my-4">Not Found</h2>
              <div className="text-lg mb-4">
                <p className="">
                  Wait and try again later. It seems the coin doesn't exist or is still
                  indexing.
                </p>
                <p>
                  {" "}
                  If it's still not working contact{" "}
                  <a
                    className="text-green-400"
                    href="https://t.me/pumpfunsupport"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support
                  </a>{" "}
                  and send the coin address.
                </p>
              </div>
              <a className="text-green-400" href="/">
                Return Home
              </a>
            </div>
          </div>
        </main>
        <Footer />
      </PageContainer>
    );
  }

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth', // Optional: adds smooth scrolling effect
    });
  };



  return (
    <PageContainer>
      {(showTradeModal && tokenInfo) && (
        <TradeModal
          open={showTradeModal}
          onClose={() => setShowTradeModal(false)}
          tradeType={tradeType}
          tokenName={tokenInfo.symbol}
          tokenAmount={tradeType === "buy" ? !toggleBuyTokenAmount ? formatUnits(totalReceived as any ?? "0", 18) : inputTradeValue : inputTradeValue}
          ethAmount={tradeType === "buy" ? !toggleBuyTokenAmount ? inputTradeValue : getAmountIn(Number(formatUnits(reserve0 as any, 18)), Number(formatUnits(reserve1 as any, 18)), Number(inputTradeValue)).toString() : formatUnits(totalReceived as any, 18)}
          tokenAddress={tokenInfo.address}
          tokenInfo={tokenInfo}
          slippage={slippage}
        />
      )}
      {generateBubbleMapsOpen && (
        <BubbleMaps
          tokenAddress={tokenAddress}
          open={generateBubbleMapsOpen}
          onClose={() => setGenerateBubbleMapsOpen(false)}
        />
      )}
      {slippageSettingsOpen && (
        <SlippageSettings
          open={slippageSettingsOpen}
          setSlippage={setSlippage}
          slippage={slippage}
          onClose={() => setSlippageSettingsOpen(false)}
        />
      )}
      {showPostReply && (
        <PostReply
          token={tokenAddress}
          open={showPostReply}
          onClose={() => setShowPostReply(false)}
          replyTo={replyTo}
        />
      )}
      {tokenInfo && (
        <main className="h-full" style={{ minHeight: '100vh' }}>
          <div className="md:hidden lg:hidden flex justify-center my-4" />

          <div className="md:hidden relative grid h-full pb-24" style={{ gridTemplateRows: "1fr auto" }}>
            <div className="h-full p-4 overflow-auto">
              {mobilePage === 0 && (
                <TokenDescription
                  tokenInfo={tokenInfo}
                  poolBalance={poolBalance}
                  reserve0={reserve0}
                  kingOfPumpProgress={kingOfPumpProgress}
                  kingOfPump={kingOfPump}
                  setGenerateBubbleMapsOpen={setGenerateBubbleMapsOpen}
                  bondingCurveProgress={bondingCurveProgress}
                  tokenHoldersData={tokenHoldersData} />
              )}
              {mobilePage === 1 && (<>
                <TopChartInfo tokenInfo={tokenInfo} tokenMarketCap={tokenMarketCap as any} handleCopy={handleCopy} />
                <ChartContainer tokenInfo={tokenInfo} allTrades={allTrades} />
              </>)}
              {mobilePage === 2 && (<>
                {tokenInfo.liquidityDeployed && (
                  <div className="p-4 w-fit bg-green-300 rounded mt-4 mb-4 text-lg">
                    sonus exchange pool seeded! view the coin on sonus exchange
                    <a className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer" href="https://www.geckoterminal.com/solana/pools/9Tb2ohu5P16BpBarqd3N27WnkF51Ukfs8Z1GzzLDxVZW">
                      here
                    </a>
                  </div>
                )}
                {tokenInfo.liquidityDeployed && (
                  <div className="bg-blue-500 p-2 rounded text-white text-lg">Trade on Sonus Exchange via PumpStation</div>
                )}
                <BuySellComponent tokenInfo={tokenInfo}
                  setSlippageSettingsOpen={setSlippageSettingsOpen}
                  setToggleBuyTokenAmount={setToggleBuyTokenAmount}
                  toggleBuyTokenAmount={toggleBuyTokenAmount}
                  setShowTradeModal={setShowTradeModal}
                  actionTab={actionTab}
                  setActionTab={setActionTab}
                  setTradeType={setTradeType}
                  setInputTradeValue={setInputTradeValue}
                  inputTradeValue={inputTradeValue}
                  handleBuyAmount={handleBuyAmount}
                  handleSellPercentage={handleSellPercentage}
                  totalReceived={totalReceived}
                  reserve0={reserve0}
                  reserve1={reserve1}
                  hasSufficientAllowance={hasSufficientAllowance}
                  isApproving={isApproving}
                  isPending={isPending}
                  isConfirming={isConfirming}
                  handleApproveTokens={handleApproveTokens}
                  getAmountIn={getAmountIn}
                />
                <ChatComponent
                  tokenInfo={tokenInfo}
                  setShowPostReply={setShowPostReply}
                  setReplyTo={setReplyTo}
                  scrollToBottom={scrollToBottom}
                />
              </>)}
              {mobilePage === 3 && (<>
                <TradesTable
                  tokenInfo={tokenInfo}
                  allTrades={allTrades}
                />
              </>)}
            </div>
            <div className="md:relative fixed bottom-0 z-10 w-full flex justify-around border-t-2 border-gray-200 py-4 bg-[#5c5f66]">
              <button onClick={() => setMobilePage(0)} className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:hover:bg-slate-800 dark:hover:text-slate-50 h-10 px-4 py-2 hover:bg-transparent hover:text-white font-bold text-white bg-transparent">
                [info]
              </button>
              <button onClick={() => setMobilePage(1)} className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:hover:bg-slate-800 dark:hover:text-slate-50 h-10 px-4 py-2 text-black hover:bg-transparent hover:text-white">
                [chart]
              </button>
              <button onClick={() => setMobilePage(2)} className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:hover:bg-slate-800 dark:hover:text-slate-50 h-10 px-4 py-2 text-black hover:bg-transparent hover:text-white">
                [buy/sell]
              </button>
              <button onClick={() => setMobilePage(3)} className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:hover:bg-slate-800 dark:hover:text-slate-50 h-10 px-4 py-2 text-black hover:bg-transparent hover:text-white">
                [txs]
              </button>
            </div>
          </div>




          <div className="md:block hidden mt-16 p-4 mb-16">
            <div className="flex justify-center">
              <a
                className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-lg ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 dark:hover:bg-slate-800 dark:hover:text-slate-50 h-10 px-4 py-2 -mt-5 text-2xl text-slate-50 hover:font-bold hover:bg-transparent hover:text-slate-50"
                href="/board"
              >
                [go back]
              </a>
            </div>
            {tokenInfo.liquidityDeployed && (
              <div className="p-4 w-fit bg-green-300 rounded mt-4 mb-4 text-lg">
                sonus exchange pool seeded! view the coin on sonus exchange
                <a className="text-blue-500 hover:underline" target="_blank" rel="noopener noreferrer" href="https://www.geckoterminal.com/solana/pools/9Tb2ohu5P16BpBarqd3N27WnkF51Ukfs8Z1GzzLDxVZW">
                  here
                </a>
              </div>
            )}
            <div className="flex space-x-8 mt-4">
              <div className="flex flex-col gap-2 w-2/3">
                <TopChartInfo tokenInfo={tokenInfo} tokenMarketCap={tokenMarketCap as any} handleCopy={handleCopy} />
                <ChartContainer tokenInfo={tokenInfo} allTrades={allTrades} />
                <div className="flex gap-2 h-fit mt-4">
                  <div className={`text-lg cursor-pointer px-1 py-1 rounded ${toggleChatOrTrades === true ? "text-black bg-green-300" : "text-gray-500"}`}
                    onClick={() => setToggleChatOrTrades(true)}>
                    Thread
                  </div>
                  <div className={`text-lg cursor-pointer px-1 py-1 rounded ${toggleChatOrTrades === false ? "text-black bg-green-300" : "text-gray-500"}`}
                    onClick={() => setToggleChatOrTrades(false)}>
                    Trades
                  </div>
                </div>
                {toggleChatOrTrades ? (
                  <ChatComponent
                    tokenInfo={tokenInfo}
                    setShowPostReply={setShowPostReply}
                    setReplyTo={setReplyTo}
                    scrollToBottom={scrollToBottom}
                  />
                ) : (
                  <TradesTable
                    tokenInfo={tokenInfo}
                    allTrades={allTrades}
                  />
                )}

              </div>
              <div className="w-1/3 grid gap-4 h-fit w-fit">
                {tokenInfo.liquidityDeployed && (
                  <div className="bg-blue-500 p-2 rounded text-white text-lg">Trade on Sonus Exchange via PumpStation</div>
                )}
                <BuySellComponent tokenInfo={tokenInfo}
                  setSlippageSettingsOpen={setSlippageSettingsOpen}
                  setToggleBuyTokenAmount={setToggleBuyTokenAmount}
                  toggleBuyTokenAmount={toggleBuyTokenAmount}
                  setShowTradeModal={setShowTradeModal}
                  actionTab={actionTab}
                  setActionTab={setActionTab}
                  setTradeType={setTradeType}
                  setInputTradeValue={setInputTradeValue}
                  inputTradeValue={inputTradeValue}
                  handleBuyAmount={handleBuyAmount}
                  handleSellPercentage={handleSellPercentage}
                  totalReceived={totalReceived}
                  reserve0={reserve0}
                  reserve1={reserve1}
                  hasSufficientAllowance={hasSufficientAllowance}
                  isApproving={isApproving}
                  isPending={isPending}
                  isConfirming={isConfirming}
                  handleApproveTokens={handleApproveTokens}
                  getAmountIn={getAmountIn}
                />
                <TokenDescription
                  tokenInfo={tokenInfo}
                  poolBalance={poolBalance}
                  reserve0={reserve0}
                  kingOfPumpProgress={kingOfPumpProgress}
                  kingOfPump={kingOfPump}
                  setGenerateBubbleMapsOpen={setGenerateBubbleMapsOpen}
                  bondingCurveProgress={bondingCurveProgress}
                  tokenHoldersData={tokenHoldersData}
                />
              </div>
            </div>
          </div>
        </main>
      )}
      {/* <main className="h-full" style={{ minHeight: '100vh' }}>
          <div className="text-white flex items-center justify-center text-center mt-20 h-fit mx-auto p-4">
            <div className="">
              <svg
                className="w-80 mx-auto"
                viewBox="0 0 539 451"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M36 295.5L492 116C492 116 547.5 135 537.5 228C527.5 321 390 372 390 372L300 345L332.5 408.5C267.5 438.5 213.5 452.5 182.5 450.5C151.5 448.5 115.5 449 74 403.5C32.5 358 36 295.5 36 295.5Z"
                  fill="black"
                  fillOpacity="0.1"
                />
                <path
                  d="M470.947 161.304C432.771 200.378 401.81 229.18 380.427 248.187C369.735 257.69 361.438 264.745 355.831 269.411C355.242 269.901 354.683 270.364 354.154 270.801L233.113 107.167C235.279 105.188 238.461 102.418 243.094 98.6121C257.347 86.902 284.906 65.7353 337.904 28.1688C386.822 -6.50546 437.236 8.80343 467.214 42.2822C497.544 76.1541 505.202 126.243 470.947 161.304Z"
                  fill="white"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M351.065 149.236C367.757 172.887 375.745 198.897 375.516 221.589C375.288 244.295 366.925 262.971 351.737 273.69C336.548 284.409 316.15 286.032 294.679 278.641C273.223 271.254 251.392 255.013 234.7 231.363C218.008 207.712 210.02 181.702 210.248 159.01C210.477 136.304 218.84 117.628 234.028 106.909C249.217 96.1897 269.615 94.5665 291.085 101.958C312.542 109.345 334.373 125.586 351.065 149.236Z"
                  fill="#E1E1E1"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  d="M16.0141 246.223C7.54956 186.447 49.3591 131.097 109.398 122.596L219.075 107.065C219.075 107.065 242.161 156.219 249.79 221.618C257.419 287.017 249.728 323.532 249.728 323.532L140.051 339.062C80.0118 347.564 24.4786 305.999 16.0141 246.223Z"
                  fill="#5FCB88"
                />
                <path
                  d="M110.727 130.019L214.27 115.357C214.602 116.154 214.962 117.031 215.347 117.984C217.577 123.498 220.647 131.564 223.958 141.664C230.587 161.887 238.146 190.155 241.908 222.374C245.694 254.792 245.683 279.958 244.747 296.919C244.279 305.4 243.58 311.832 243.008 316.095C242.991 316.218 242.975 316.34 242.958 316.46L139.208 331.152C83.6668 339.016 32.266 300.367 24.4011 244.826C16.5362 189.285 55.1855 137.884 110.727 130.019Z"
                  stroke="#1D3934"
                  strokeWidth={17}
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M69.0978 252.344C71.1796 251.763 73.3384 252.979 73.9197 255.061L75.8974 262.144C76.4787 264.226 75.2623 266.385 73.1805 266.966C71.0987 267.548 68.9399 266.331 68.3586 264.249L66.3809 257.166C65.7996 255.084 67.016 252.925 69.0978 252.344ZM79.9079 277.183C81.461 275.68 83.9387 275.721 85.4418 277.274C92.6431 284.715 101.241 289.943 110.217 292.733C112.281 293.374 113.434 295.568 112.792 297.632C112.15 299.696 109.957 300.849 107.893 300.207C97.6304 297.017 87.9063 291.075 79.8173 282.717C78.3141 281.164 78.3547 278.686 79.9079 277.183ZM124.884 300.453C124.554 298.317 126.018 296.318 128.154 295.988L137.353 294.569C139.489 294.239 141.488 295.703 141.818 297.839C142.148 299.976 140.683 301.975 138.547 302.304L129.348 303.724C127.212 304.054 125.213 302.589 124.884 300.453Z"
                  fill="white"
                />
              </svg>
              <h2 className=" text-4xl my-4">Something went wrong</h2>
              <div className="text-lg mb-4">
                <p className="">Wait and try again later. Something failed.</p>
                <p>
                  {" "}
                  If it's still not working after 1 to 5 minutes, contact{" "}
                  <a
                    className="text-green-400"
                    href="https://t.me/pumpfunsupport"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support
                  </a>{" "}
                  and send the coin address.
                </p>
              </div>
              <button className="text-green-400">Try again</button>
            </div>
          </div>
        </main> */}
      {loadingTokenInfo && (
        <main className="h-full" style={{ minHeight: '100vh' }}>
          <div className="text-white flex items-center justify-center text-center mt-20 h-fit mx-auto p-4">
            <div>
              <img src={LogoPumpStation} width={200} height={200} alt="Pump Station" className="mx-auto" />
              <h2 className=" text-4xl my-4">Loading...</h2>
            </div>
          </div>
        </main>
      )}

      <Footer>
        {tokenInfo ? (<a
          rel="noopener noreferrer"
          className="flex-shrink-0"
          href={`/report/coins/${tokenInfo.address}`}
        >
          Report
        </a>) : null}
      </Footer>
    </PageContainer>
  );
}
export default TokenPage;