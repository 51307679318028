import React, { useEffect, useRef, useState } from "react";
import ButtonMenu from "../../../components/ButtonMenu";

interface SlippageSettingsProps {
  open: boolean;
  onClose: () => void;
  setSlippage: (value: number) => void;
  slippage: number;
}

const SlippageSettings: React.FC<SlippageSettingsProps> = ({ open, onClose, slippage, setSlippage }) => {

  const modalRef = useRef<HTMLDivElement>(null);

  const [frontRunningProtection, setFrontRunningProtection] = useState(true);


  // Handle clicks outside the modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    // Add event listener to detect clicks
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove event listener when component is unmounted or `open` changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onClose]);

  // Return null if the modal is not open
  if (!open) return null;

  return (
    <div data-state="open" className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" style={{ pointerEvents: "auto" }} data-aria-hidden="true" aria-hidden="true">
      <div
        ref={modalRef}
        role="dialog"
        id="radix-:r4f:"
        aria-describedby="radix-:r4h:"
        aria-labelledby="radix-:r4g:"
        data-state="open"
        className="fixed left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] gap-4 border border-slate-200 p-6 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg dark:border-slate-800 dark:bg-slate-950 bg-primary text-white max-w-[400px]"
        tabIndex={-1}
        style={{ pointerEvents: "auto", border: "1px solid" }}
      >
        <div className="text-sm grid gap-2 w-fit justify-self-center" style={{textAlign: 'left'}}>
          <div>Set max. slippage (%)</div>
          <input
            className="bg-[#2a2a3b] border border-slate-200 rounded-md p-2 w-fit"
            id="slippage"
            placeholder=""
            type="number"
            onChange={(e) => setSlippage(parseFloat(e.target.value))}
            value={slippage}
          />
          <div className="text-sm text-gray-400">
            This is the maximum amount of slippage you are willing to accept when
            placing trades
          </div>
          <div className="text-sm flex gap-1 h-fit items-center text-white">
            <div>Enable front-running protection:</div>
            <ButtonMenu onClick={() => setFrontRunningProtection(true)} active={frontRunningProtection}>On</ButtonMenu>
            <ButtonMenu onClick={() => setFrontRunningProtection(false)} active={!frontRunningProtection}>Off</ButtonMenu>
          </div>
          <div className="text-sm grid gap-2 text-left">
            <div>Priority fee</div>
            <div className="flex items-center rounded-md relative bg-[#2e303a]">
              <input
                className="flex h-10 rounded-md border border-slate-200 px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 bg-transparent text-white outline-none w-full pl-3"
                id="amount"
                placeholder={"0.0"}
                type="number"
                defaultValue="0.01"
              />
              <div className="flex items-center ml-2 absolute right-2">
                <span className="text-white mr-2">ETH</span>
                <img
                  alt="ETH"
                  loading="lazy"
                  width={32}
                  height={32}
                  decoding="async"
                  data-nimg={1}
                  className="w-8 h-8 rounded-full"
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
                  style={{ color: "transparent" }}
                />
              </div>
            </div>
            <div className="text-sm text-gray-400">
              A higher gas fee will make your transactions confirm faster. This is
              the transaction fee that you pay to the network on each trade.
            </div>
          </div>
          <div className="text-slate-50 hover:font-bold hover:text-slate-50 cursor-pointer w-fit justify-self-center" onClick={onClose}>
            [close]
          </div>
        </div>

      </div>
    </div>
  );
}
export default SlippageSettings;