// define the FarmPage component
import React from "react";
import "./tokenpage.css";
import CandlestickChart from "../CandlestickChart";
import { CreatedTokenInfo } from "../../../../types";

interface ChartContainerProps {
  tokenInfo: CreatedTokenInfo;
  allTrades: any;
}

const ChartContainer: React.FC<ChartContainerProps> = ({ tokenInfo, allTrades }) => {

  return (
    <div className="h-4/8">
      <div className="grid h-fit gap-2">
        <div className="chart-container ">
          {tokenInfo.liquidityDeployed ? (
            <div id="dexscreener-embed">
              <iframe
                id="geckoterminal-embed"
                title="GeckoTerminal Embed"
                src="https://www.geckoterminal.com/solana/pools/9Tb2ohu5P16BpBarqd3N27WnkF51Ukfs8Z1GzzLDxVZW?embed=1&info=0&swaps=0&grayscale=1"
                allow="clipboard-write"
                // allowFullScreen=""
                style={{ height: 400, width: "100%" }}
              />
            </div>
          ) : (
            allTrades && allTrades.length > 0 && <CandlestickChart trades={allTrades} />
          )}
          {/* <div
                        id="tv-chart-386gfkm09oj"
                        className=""
                        style={{ height: 400, width: "100%" }}
                      >
                        <iframe
                          id="tradingview_f8ce9"
                          name="tradingview_f8ce9"
                          src="blob:https://pump.fun/05ce7c07-75f5-4b2c-b2bc-70fcbf041742"
                          data-widget-options="symbol=BLUNT&interval=5&widgetbar=%7B%22details%22%3Afalse%2C%22watchlist%22%3Afalse%2C%22news%22%3Afalse%2C%22datawindow%22%3Afalse%2C%22watchlist_settings%22%3A%7B%22default_symbols%22%3A%5B%5D%7D%7D&timeFrames=%5B%7B%22text%22%3A%225y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%221y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%226m%22%2C%22resolution%22%3A%22120%22%7D%2C%7B%22text%22%3A%223m%22%2C%22resolution%22%3A%2260%22%7D%2C%7B%22text%22%3A%221m%22%2C%22resolution%22%3A%2230%22%7D%2C%7B%22text%22%3A%225d%22%2C%22resolution%22%3A%225%22%7D%2C%7B%22text%22%3A%221d%22%2C%22resolution%22%3A%221%22%7D%5D&locale=en&uid=tradingview_f8ce9&clientId=0&userId=0&chartsStorageVer=1.0&debug=false&timezone=Etc%2FUTC&theme=dark"
                          title="Financial Chart"
                          frameBorder={0}
                          // allowTransparency="true"
                          scrolling="no"
                          // allowFullScreen=""
                          style={{ display: "block", width: "100%", height: "100%" }}
                        ></iframe>
                      </div> */}
          {/* <div className="hidden">
                        <div id="dexscreener-embed">
                          <iframe
                            id="geckoterminal-embed"
                            title="GeckoTerminal Embed"
                            src="https://www.geckoterminal.com/solana/pools/null?embed=1&info=0&swaps=0&grayscale=1"
                            allow="clipboard-write"
                            // allowFullScreen=""
                            style={{ height: 400, width: "100%" }}
                          />
                        </div>
                      </div> */}
        </div>
      </div>
    </div>
  );
}
export default ChartContainer;