// define the FarmPage component
import React, { useEffect, useState } from "react";
import "./tokenpage.css";
import { CreatedTokenInfo } from "../../../../types";
import { formatUnits } from "viem";

interface TopChartInfoProps {
  tokenInfo: CreatedTokenInfo;
  tokenMarketCap: string;
  handleCopy: () => void;
}

const TopChartInfo: React.FC<TopChartInfoProps> = ({ tokenInfo, tokenMarketCap, handleCopy }) => {

  return (
    <div className="text-lg text-green-300 flex w-full justify-between items-center">
      <div className="flex gap-4 items-center">
        <div className="text-gray-400">{tokenInfo.name}</div>
        <div className="text-gray-400">Ticker: {tokenInfo.symbol}</div>
        <div>Market cap: ${Number(formatUnits(tokenMarketCap as any || "0", 18)).toFixed(2)}</div>
        <div className="text-gray-400">
          <div className="flex items-center">
            <label htmlFor="copy-ca">CA:</label>
            <input
              id="copy-ca"
              className="py-0.5 px-1 ml-2 block w-full border border-gray-700 rounded-s-sm text-lg focus-visible:outline-1 focus-visible:outline-dashed  disabled:opacity-50 disabled:pointer-events-none bg-black/10"
              // readOnly=""
              type="text"
              disabled
              defaultValue={`${tokenInfo.address}`}
            />
            <button className=" cursor-pointer bg-black/20 px-2 py-0.5 inline-flex items-center min-w-fit rounded-e-sm border border-s-0 border-gray-700 text-lg text-gray-500 hover:bg-black/40 focus-visible:outline-1 focus-visible:outline"
              onClick={handleCopy}
            >
              copy
            </button>
          </div>
        </div>
      </div>
      <div className="inline-flex items-center gap-2 text-sm">
        <span>created by</span>
        <a href={`/profile/${tokenInfo.creator.address}`}>
          <span className="flex gap-1  items-center">
            <img
              alt=""
              loading="lazy"
              width={16}
              height={16}
              decoding="async"
              data-nimg={1}
              className="rounded w-4 h-4"
              src="https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1?img-width=16&img-dpr=2&img-onerror=redirect"
              style={{ color: "transparent", display: "block" }}
            />
            <span
              className="px-1 rounded hover:underline flex gap-1 text-black"
              style={{ backgroundColor: "rgb(225, 205, 142)" }}
            >
              {tokenInfo.creator.address.substring(0, 6)}{" "}
            </span>
          </span>
        </a>
      </div>
    </div>
  );
}
export default TopChartInfo;