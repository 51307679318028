import NotFoundImg from "../assets/img/not-found.png"
export const FACTORY_CONTRACT = "0xfE77CBC976b2D41342B862B0598628AF6cEf1ba1"
export const DEFAULT_USER_PIC = "https://pump.mypinata.cloud/ipfs/QmeSzchzEPqCU1jwTnsipwcBAeH7S4bmVvFGfF65iA1BY1"

export const SCAN_URL = "https://explorer-testnet.soneium.org/"
export const DEX_NAME = "Sonus Exchange"

export const DEX_ROUTER = "0x5C17d63BF484d7aC7203e35791313c58A720903E"
export const WETH_ADDRESS = "0x4200000000000000000000000000000000000006"

// do a export function that checks if im on localhost or not
export function isLocalhost() {
    return window.location.hostname === "localhost"
}

// do other export function to get the thegraph url based on isLocalhost
export function getBaseApiUrl() {
    const forceProd = false
    if (forceProd) {
        return "https://api.pumpstation.meme"
    }
    return isLocalhost() ? "http://localhost:5000" : "https://api.pumpstation.meme"
}

export function getImageFormattedUrl(isUser: boolean, url: string, width: number, additional: string) {
    if (url === "") {
        if (isUser) {
            return DEFAULT_USER_PIC
        }
        return NotFoundImg
    }

    return `${url}${width > 0 ? `?img-width=${width}&img-dpr=2` : ""}${additional}`
}